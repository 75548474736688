import React from 'react'
import Layout from '../../../components/Default/Layout'
import ServiceHeader from '../../../components/Services/ServiceOverview/ServiceHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import './styles.css'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Image from '../../../components/Image/Image'
export const Dane = ({
  data: { page, image1, image2, image3, yellowArrow },
  pageContext,
}) => {
  const { frontmatter } = page
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <>
      <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
        metaCrumb={crumbs}
      >
        <ServiceHeader
          title={frontmatter.title}
          backgroundImage={
            !!frontmatter.image.childImageSharp
              ? frontmatter.image.childImageSharp.fluid.src
              : frontmatter.image
          }
        />
        <Breadcrumb crumbs={crumbs} crumbLabel={'Případové studie'} />
        <div className="container">
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              {!!image1 && !!image1.childImageSharp ? (
                <Img
                  fluid={image1.childImageSharp.fluid}
                  alt="pripadove-studie-1"
                />
              ) : (
                <img src={image1.src} alt="pripadove-studie-1" />
              )}
            </div>
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Registrace k DPH několika stovek daňových subjektů</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Zavedení systému tzv. call-off-stock skladů pro několik
                    desítek dodavatelů velkého obchodního řetězce
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Daňové řešení restrukturalizace významného sportovního
                    zařízení
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Navržení mnoha procesů přeměn a asistence při jejich
                    realizaci
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Studie k převodním cenám pro nadnárodní skupiny a jejich
                    obhajoba před správcem daně
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Prodej významného soukromého vzdělávacího zařízení</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Úspěšné zastupování při odvolání proti výsledkům kontroly
                    tzv. švarcsystému u výrobní společnosti
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Zavedení on-line účetnictví pro obchodní společnost nebo
                    výrobní podnik s několika desítkami zaměstnanců
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Zastupování ve sporu s Finanční správou v ČR o DPH ve výši
                    přes 30 mil. Kč
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {!!image2 && !!image2.childImageSharp ? (
                <Img
                  fluid={image2.childImageSharp.fluid}
                  alt="pripadove-studie-2"
                />
              ) : (
                <img src={image2.src} alt="pripadove-studie-2" />
              )}
            </div>
          </div>
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              {!!image3 && !!image3.childImageSharp ? (
                <Img
                  fluid={image3.childImageSharp.fluid}
                  alt="pripadove-studie-3"
                />
              ) : (
                <img src={image3.src} alt="pripadove-studie-3" />
              )}
            </div>
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Asistence při prodeji malé vodní elektrárny v ČR</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Due diligence při nákupu i prodeji mnoha společností</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Restrukturalizace výrobního závodu, jeho rozštěpení na
                    několik částí a asistenci při prodeji klíčových aktiv novému
                    vlastníkovi
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Asistence s implementací ERP systému ve výrobní společnosti
                    s obratem v jednotkách mld. Kč
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Tuzemské i mezinárodní strukturování transakcí – návrh a
                    implementace akvizičních struktur, likvidace off-shorových
                    struktur
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default Dane

export const pageQuery = graphql`
  query PripadoveStudie {
    page: markdownRemark(
      frontmatter: { slug: { eq: "o-nas/pripadove-studie" } }
    ) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    image1: file(relativePath: { eq: "pripadove-studie1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "pripadove-studie2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "pripadove-studie3.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yellowArrow: file(relativePath: { eq: "yellow-arrow.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`
